@media (prefers-color-scheme: dark) {
  dialog {
    color: #fff;
    background-color: var(--black-b);
  }

  dialog header {
    background-color: var(--gray-c) !important;
  }
}

@keyframes toggle-modal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

dialog {
  border-radius: var(--border-radius-dialog);
  border-width: 0;
  padding: 15px;
}

dialog.small {
  width: min(85%, 400px);
}

dialog.large {
  width: min(85%, 1000px);
}

dialog .content {
  text-align: center;
  flex-direction: column;
  gap: 8px;
  display: flex;
}

dialog::backdrop {
  background-color: #0000004d;
}

dialog:not([open]) {
  display: none;
}

dialog[open] {
  animation: .2s ease-in-out toggle-modal;
}

dialog header > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog header {
  background-color: var(--gray-d);
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

dialog header .close {
  pointer-events: all;
  user-select: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

dialog textarea {
  flex-grow: 1;
}

dialog a:not(.anchor) {
  color: var(--light-blue);
  text-decoration: none;
}

dialog a:not(.anchor):hover {
  color: var(--light-blue);
  text-decoration: underline;
}

dialog section {
  flex-direction: column;
  gap: 10px;
  display: flex;
  overflow: scroll;
}

dialog form {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

dialog .highlighted-stop {
  font-style: italic;
}

.left, .right {
  flex-basis: auto;
}

.left {
  margin-left: 10px;
}

.middle {
  text-align: center;
  flex-grow: 1;
}
/*# sourceMappingURL=index.0b82b49c.css.map */
