@media (prefers-color-scheme: dark) {
  dialog {
    color: white;
    background-color: var(--black-b);
  }

  dialog header {
    background-color: var(--gray-c) !important;
  }
}

@keyframes toggle-modal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

dialog {
  border-width: 0;
  border-radius: var(--border-radius-dialog);
  padding: 15px;
}

dialog.small {
  width: min(85%, 400px);
}

dialog.large {
  width: min(85%, 1000px);
}

dialog .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

dialog:not([open]) {
  display: none;
}

dialog[open] {
  animation: toggle-modal 0.2s ease-in-out;
}

dialog header > * {
  margin-top: 5px;
  margin-bottom: 5px;
}

dialog header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;

  background-color: var(--gray-d);

  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}

dialog header .close {
  width: 50px;
  height: 50px;

  pointer-events: all;
  user-select: none;
  cursor: pointer;
}

dialog textarea {
  flex-grow: 1;
}

dialog a:not(.anchor) {
  color: var(--light-blue);
  text-decoration: none;
}

dialog a:not(.anchor):hover {
  color: var(--light-blue);
  text-decoration: underline;
}

dialog section {
  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow: scroll;
}

dialog form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

dialog .highlighted-stop {
  font-style: italic;
}

.left,
.right {
  flex-basis: auto;
}

.left {
  margin-left: 10px;
}

.middle {
  flex-grow: 1;
  text-align: center;
}
